<template>
  <section class="site-section" id="next-section">
    <div class="container">
      <div class="row mb-3">
        <div class="col-12 text-center" data-aos="fade">
          <h2 class="section-title mb-3">{{$options.name}}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="p-4 mb-3">
            <img :src="info.kontakt.logo" v-if="info.kontakt.logo" style="width:50%; margin-bottom:1em;" />
            <br />
            <p class="mb-4">
              <ul class="list-unstyled" v-if="info">
                <li>{{ info.kontakt.meno }}</li>
                <li v-if="info.kontakt.tel">
                  Telefón:
                  <a :href="'callto:' + info.kontakt.tel">
                    {{ info.kontakt.tel }}
                  </a>
                </li>
                <li v-if="info.kontakt.email">
                  E-mail:
                  <a :href="'mailto:' + info.kontakt.email">
                    {{ info.kontakt.email }}
                  </a>
                </li>
                <li v-if="info.kontakt.ico">
                  IČO: {{ info.kontakt.ico}}
                </li>
              </ul>
            </p>

            <p class="mb-0 font-weight-bold" v-if="info && info.prevadzka">Prevádzka</p>
            <p class="mb-4">
              <ul class="list-unstyled" v-if="info && info.prevadzka">
                <li>{{info.prevadzka.ulica}} {{info.prevadzka.cislo}},</li>
                <li>{{info.prevadzka.psc}}  {{info.prevadzka.mesto}}</li>
                <li v-if="info.prevadzka.platba_kartou"><strong><em>Na prevádzke je možné platiť platobnou kartou.</em></strong></li>
              </ul>
            </p>

            <p class="mb-0 font-weight-bold">Otváracia doba</p>
            <p class="mb-4">
              <ul class="list-unstyled" v-if="info">
                <li v-for="den in info.otvaraciaDoba.dni" :key="den.odrer">
                  {{ den.nazov }} : {{ den.zaciatok }} - {{ den.koniec }}
                </li>
                <li>Obedná pauza: {{ getPauza() }}</li>
              </ul>
            </p>
            <div v-if="info && info.partneri">
              <div v-for="partner in info.partneri" :key="partner.titulok">

                <p class="mb-0 font-weight-bold">{{partner.titulok}}</p>
                <p class="mb-0">
                  <ul class="list-unstyled">
                    <li v-if="partner.meno">{{partner.meno}}</li>
                    <li v-if="partner.tel">
                      <a :href="'callto:' + partner.tel">{{partner.tel}}</a>
                    </li>
                    <li v-if="partner.email">
                      <a :href="'mailto:' + partner.email">{{partner.email}}</a>
                    </li>
                    <li v-if="partner.web">
                      <a :href="getUrlWithHttp(partner.web)" target="_blank">{{partner.web}}</a>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <form @submit.prevent="formSubmit()" id="messageForm">

              <div class="row form-group">

                <div class="col-md-12">
                  <label class="text-black" for="name">Meno</label>
                  <input v-model="form.name" type="text" id="name" class="form-control" required minlength="5">
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-12">
                  <label class="text-black" for="email">E-mail</label>
                  <input v-model="form.email" type="email" id="email" class="form-control" required>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-12">
                  <label class="text-black" for="message">Správa</label>
                  <textarea v-model="form.message" name="message" id="message" cols="30" rows="7" class="form-control" required></textarea>
                </div>
              </div>


              <div class="row form-group">
                <div class="col-md-12">
                  <label class="text-black" for="antiSpam">Ako ochrana proti robotom, napíšte do políčka nižšie aký je dnes deň.</label>
                  <input v-model="form.antiSpam" type="text" id="antiSpam" autocomplete="off" class="form-control" required>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-12">
                  <p>Kliknutím na tlačidlo <em>'Poslať správu'</em> súhlasím so spracovaním <router-link to="gdpr" style="font-weight:bold;">osobných udajov</router-link> a vyhlasujem, že som sa zoznámil s podmienkami <a href="/pdf/07_INFO_Kontakty_202103.pdf" target="_blank" style="font-weight:bold;">spracovania osobnych udajov</a>.</p>
                </div>
              </div>

              <div class="row form-group">
                <div class="col-md-12">
                  <input type="submit" value="Poslať správu" class="btn btn-primary btn-md text-white" />
                </div>
              </div>


            </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <!--iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d658.5332991415851!2d19.126178!3d48.683818!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x81fa7111a5971192!2sAndrej%20Dula%20-%20Predaj%20pr%C3%ADrodn%C3%A9ho%20kame%C5%88a!5e0!3m2!1ssk!2ssk!4v1603033110555!5m2!1ssk!2ssk" frameborder="0" style="border:0; width:100%; height:500px;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe-->
          <iframe src="https://www.openstreetmap.org/export/embed.html?bbox=19.124927886296064%2C48.68316596290193%2C19.12811971502379%2C48.68446575324964&amp;layer=mapnik&amp;marker=48.68381586226888%2C19.126523800659925" style="border:0; width: 100%; height:500px;"></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Kontakt",
  metaInfo() {
    return {
      title: this.metaTagy.title,
      meta : [
        { name:"title", content: this.metaTagy.title },
        { name:"description", content: this.metaTagy.description },
        { name:"keywords", content: this.metaTagy.keywords },
        { property:"og:type", content:"website" },
        { property:"og:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"og:title", content: this.metaTagy.title },
        { property:"og:description", content: this.metaTagy.description },
        { property:"twitter:card", content:"summary_large_image" },
        { property:"twitter:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"twitter:title", content: this.metaTagy.title },
        { property:"twitter:description", content: this.metaTagy.description },
      ]
    }
  },
  data() {
    return {
      metaTagy: {
        title: "Kontakt | Predaj prírodného kameňa",
        keywords: "kontakt, andrej dula, kamen, predaj kamena, prirodny kamen, predaj prirodneho kamena, banska bystrica",
        description:"Kontaktné údaje na predajcu prírodného kameňa v Banskej Bystrici.",
        urlPath: this.$router.fullpath
      },
      info: null,
      form: {
        name: "",
        email: "",
        message: "",
        antiSpam: "",
      },
    };
  },
  async mounted() {
    console.debug(this.$route);
    this.$axios
      .get("/data/info.json")
      .then((response) => (this.info = response.data))
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    formSubmit() {
      this.$toast.info("Správa sa odosiela.", "Info.");
      var formValidation = this.validateFormFields();
      if (formValidation.valid == false) {
        this.$toast.error(formValidation.message);
        return;
      }

      var url = window.location.origin + process.env.BASE_URL + "backend/support.php";
      var msgData = {
          meno: this.form.name,
          email: this.form.email,
          sprava: this.form.message,
          antiSpam: this.form.antiSpam,
        };
      var toast = this.$toast;
      var post = this.$axios.post(url, msgData);
      var ths = this;
      post.then(function (response) {
          if (response.data.state == "success") {
            toast.success(response.data.message,"OK!");
            ths.clearForm();
          } else {
            toast.error(response.data.message,"Chyba!");
          }
        })
        .catch(function (error) {
          toast.error("Nastala neocakavana chyba. " + error, "Chyba!");
        });

    },
    validateFormFields() {
      var status = {
        valid: true,
        message: "",
      };

      if (this.form.name == "") {
        status.valid = false;
        status.message = "Meno niesmie byť prázdne!";
      } else if (this.form.email == "") {
        status.valid = false;
        status.message = "Email niesmie byť prázdny!";
      } else if (this.form.message == "") {
        status.valid = false;
        status.message = "Správa niesmie byť prázdna!";
      } else if (this.form.antiSpam == "") {
        status.valid = false;
        status.message = "Neodpovedali ste na otázku aký je deň!";
      }

      return status;
    },
    clearForm() {
        this.form.name = "";
        this.form.email = "";
        this.form.message = "";
        this.form.antiSpam = "";
    },
    getPauza() {
      if (
        this.info === undefined &&
        this.info.otvaraciaDoba === undefined &&
        this.info.otvaraciaDoba.pauza === undefined
      )
        return "";
      return (
        this.info.otvaraciaDoba.pauza.zaciatok +
        "-" +
        this.info.otvaraciaDoba.pauza.koniec
      );
    },
    getUrlWithHttp (url) {
      if (url === undefined || url == null) return "";
      if (url.startsWith("http://") || url.startsWith("https://")) return url;
      return "http://" + url;
    }
  },
};
</script>
